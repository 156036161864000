import type { Context, Properties } from "world.ts";
import { createLineLayer } from "world.ts";

import type { Position } from "../model";
import { blue } from "./color";

export type PathLayerProperties = {
  path: Position[];
};

export const createPathLayer = (
  context: Context,
  properties: Properties<PathLayerProperties>,
) => {
  const { path } = properties;
  return createLineLayer(context, {
    points: () => [path()],
    color: () => blue,
    minWidthPixels: () => 5,
    maxWidthPixels: () => 5,
    polygonOffset: () => -1000,
    pickable: () => false,
  });
};
