<script lang="ts">
  import Overlay from "./Overlay.svelte";

  export let visible: boolean;
</script>

<div class="message" class:visible>
  <Overlay>
    <div class="container">
      <slot />
    </div>
  </Overlay>
</div>

<style>
  .message {
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0;
    transform: translateY(8rem);
    transition: transform 200ms;
    pointer-events: none;

    & .container {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    & > * {
      pointer-events: all;
    }

    &.visible {
      transform: none;
    }
  }
</style>
