<script lang="ts">
  import { onDestroy, onMount } from "svelte";

  import {
    defaultAltitude as defaultDropAltitude,
    defaultGlideSlope,
  } from "../configuration";
  import { connect } from "../connect";
  import type { Editing } from "../model";
  import { type Bounds, type Drop, type Vehicle } from "../model";
  import { requestSerialPort } from "../serial";
  import Compass from "./Compass.svelte";
  import Message from "./Message.svelte";
  import Search from "./Search.svelte";
  import Settings from "./Settings.svelte";
  import Sidebar from "./Sidebar.svelte";
  import World from "./World.svelte";

  let editing: Editing | undefined;
  let vehicle: Vehicle | undefined;
  let drop: Drop = {
    start: [0, 0, 0],
    end: [0, 0, 0],
    drop: [0, 0, 0],
    path: [],
    glideSlope: defaultGlideSlope,
    dropAltitude: defaultDropAltitude,
  };
  let connected = false;
  let loading = false;
  let highlight: Bounds | undefined;

  $: connecting = !!vehicle && (!connected || loading);

  const onEditing = (_: Editing | undefined) => {
    editing = _;
  };

  const onDoneEditing = () => onEditing(undefined);

  const onChangeDrop = (_: Partial<Drop>) => {
    drop = { ...drop, ..._ };
  };

  const onGeocode = (_: Bounds | undefined) => {
    highlight = _;
  };

  const onConnect = async () => {
    if (vehicle) vehicle.destroy();
    vehicle = undefined;
    await requestSerialPort();
    vehicle = await connect();
    loading = true;
    try {
      await vehicle.load();
    } finally {
      loading = false;
    }
    drop = vehicle.state.drop ?? drop;
  };

  onMount(onConnect);
  onDestroy(() => vehicle?.destroy());

  onMount(() => {
    const interval = setInterval(() => {
      connected = Date.now() - (vehicle?.state.time ?? 0) < 5000;
    });
    return () => clearInterval(interval);
  });
</script>

<div class="app">
  <World
    {vehicle}
    {drop}
    {editing}
    {highlight}
    {onChangeDrop}
    {onDoneEditing}
  />
  <Sidebar {vehicle} {drop} {onChangeDrop} {onEditing} />
  <Settings {onConnect} />
  <Search {onGeocode} />
  <Compass />
  <Message visible={!connecting && editing === "start"}>
    Select drop zone start
  </Message>
  <Message visible={!connecting && editing === "end"}>
    Select drop zone end
  </Message>
  <Message visible={!connecting && editing === "drop"}>
    Select drop position
  </Message>
  <Message visible={connecting}>Connecting...</Message>
</div>

<style>
  @import "https://fonts.googleapis.com/css2?family=Exo+2:ital,wght@0,100..900;1,100..900&display=swap";

  :global(:root) {
    --background: black;
    font-size: 16px;
    font-family: "Exo 2", sans-serif;
    font-variant: tabular-nums;
  }

  :global(html) {
    background: var(--background);
    color: white;
    font-size: 16px;
    overscroll-behavior: none;
  }

  :global(body) {
    margin: 0;
    overscroll-behavior: none;
    overflow: hidden;
    color-scheme: dark;
  }

  .app {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
</style>
