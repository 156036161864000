<script lang="ts">
  export let label: string;
</script>

<label class="field">{label}<slot /></label>

<style>
  .field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.5rem;
    background: #ffffff22;
    border: 0.125rem solid transparent;
    border-radius: 0.25rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    transition: border-color 200ms;

    &:hover,
    &:has(input:focus) {
      border-color: white;
    }

    & input {
      appearance: none;
      background: transparent;
      color: inherit;
      border: none;
      outline: none;
      font-family: inherit;
      font-size: 1rem;
      font-variant: inherit;
      text-transform: none;

      &::placeholder {
        color: #ffffff55;
      }
    }
  }
</style>
