import { quat } from "gl-matrix";
import type { Context, Mesh, Properties } from "world.ts";
import {
  createContainer,
  createObjectLayer,
  createOrientationTransition,
  createPositionVelocityTransition,
  degrees,
} from "world.ts";

import type { State } from "../model";
import { scaleOpacity } from "./color";
import { loadObj } from "./obj";
import { createStemLayer } from "./stem";

const obj = new URL("./silent-arrow.obj", import.meta.url).toString();
const outlineObj = new URL(
  "./silent-arrow-outline.obj",
  import.meta.url,
).toString();

export type VehicleLayerProperties = {
  state: State | undefined;
  onClick: () => void;
};

export const createVehicleLayer = (
  context: Context,
  properties: Properties<VehicleLayerProperties>,
) => {
  const { state, onClick } = properties;

  const size = 1;
  const minSizePixels = 12;

  let mesh: Mesh | undefined;
  let outlineMesh: Mesh | undefined;

  const position = createPositionVelocityTransition(
    () => state()?.position ?? [0, 0, 0],
  );
  const _orientation = createOrientationTransition(
    () => state()?.orientation ?? [0, 0, 0],
  );
  const orientation = () => {
    const [pitch = 0, yaw = 0, roll = 0] = _orientation();
    return quat.fromEuler(
      quat.create(),
      90 + degrees(pitch),
      -degrees(roll),
      180 + degrees(yaw),
    );
  };

  const color = () => scaleOpacity([0.5, 0.5, 0.5, 1], state() ? 1 : 0);

  const modelLayer = createObjectLayer(context, {
    mesh: () => mesh,
    position,
    orientation,
    size: () => size,
    minSizePixels: () => minSizePixels,
    color,
    diffuse: color,
    onClick,
  });
  const modelOutlineLayer = createObjectLayer(context, {
    mesh: () => outlineMesh,
    position,
    orientation,
    size: () => size,
    minSizePixels: () => minSizePixels,
    color: () => [0, 0, 0, state() ? 1 : 0],
    pickable: () => false,
  });
  const stemLayer = createStemLayer(context, {
    position,
    color: () => [1, 1, 1, state() ? 1 : 0],
  });

  const load = async () => {
    mesh = await loadObj(obj);
    outlineMesh = await loadObj(outlineObj);
  };

  void load();

  return createContainer([modelLayer, modelOutlineLayer, stemLayer]);
};
