import type { Drop, Point, Position } from "./model";
import { feetPerMeter } from "./world/constants";
import { groundBearing, groundDistance } from "./world/math";

const initialAltitudeLoss = 1000 / feetPerMeter;
const turnAltitudeLoss = 1000 / feetPerMeter;

export const dropPath = ({ drop, path, glideSlope, dropAltitude }: Drop) => {
  const positions: Position[] = [];
  let altitude = dropAltitude;
  const [longitude, latitude] = drop;
  if (longitude === 0 && latitude === 0) return [];
  const position: Position = [longitude, latitude, altitude];
  const point: Point = [longitude, latitude];
  positions.push(position);
  altitude -= initialAltitudeLoss;
  positions.push([longitude, latitude, altitude]);

  const _path = path.filter(
    ([longitude, latitude]) => longitude !== 0 && latitude !== 0,
  );

  for (let i = 0; i < _path.length; i++) {
    const previous = _path[i - 1] ?? point;
    const current = _path[i] ?? [0, 0];
    const next = _path[i + 1] ?? [0, 0];
    const [longitude, latitude] = current;
    const bearing = groundBearing(previous, current);
    altitude -= glideAltitudeDrop(previous, current, glideSlope);
    positions.push([longitude, latitude, altitude]);
    if (i === _path.length - 1) continue;
    altitude -= Math.min(
      groundDistance(current, next),
      turnAltitudeDrop(current, next, bearing),
    );
    positions.push([longitude, latitude, altitude]);
  }

  return positions;
};

export const altitudeDrop = (
  a: Position | Point,
  b: Position | Point,
  bearing: number,
  glideSlope: number,
) => glideAltitudeDrop(a, b, glideSlope) + turnAltitudeDrop(a, b, bearing);

const glideAltitudeDrop = (
  a: Position | Point,
  b: Position | Point,
  glideSlope: number,
) => groundDistance(a, b) / glideSlope;

const turnAltitudeDrop = (
  a: Position | Point,
  b: Position | Point,
  bearing: number,
) => {
  const turnAngle = groundBearing(a, b) - bearing;
  return (1 - Math.cos(turnAngle)) * turnAltitudeLoss;
};

export const finalDropPosition = (drop: Drop) => {
  const [final] = dropPath(drop).slice(-1);
  return final;
};
