<script lang="ts">
  import { onMount } from "svelte";

  import { setWorld } from "../context";
  import type { Editing } from "../model";
  import { type Bounds, type Drop, type Vehicle } from "../model";
  import type { World } from "../world";
  import { createWorld } from "../world";

  export let vehicle: Vehicle | undefined;
  export let drop: Drop;
  export let editing: Editing | undefined;
  export let highlight: Bounds | undefined;

  export let onChangeDrop: (_: Partial<Drop>) => void;
  export let onDoneEditing: () => void;

  let element: HTMLCanvasElement | undefined;
  let world: World | undefined;

  onMount(() => {
    if (!element) return;

    world = createWorld(element, {
      state: () => vehicle?.state,
      drop: () => drop,
      editing: () => editing,
      highlight: () => highlight,
      onChangeDrop,
      onDoneEditing,
    });
    setWorld(world);

    return () => world?.dispose();
  });
</script>

<canvas bind:this={element} />

<style>
  canvas {
    width: 100vw;
    height: 100vh;
    cursor: pointer;
  }
</style>
