<script lang="ts">
  import Button from "./Button.svelte";
  import SettingsIcon from "./icons/SettingsIcon.svelte";
  import Overlay from "./Overlay.svelte";

  export let onConnect: () => void;
</script>

{#if navigator.serial}
  <div class="settings">
    <Overlay>
      <Button icon on:click={onConnect}>
        <SettingsIcon />
      </Button>
    </Overlay>
  </div>
{/if}

<style>
  .settings {
    position: absolute;
    top: 1rem;
    left: 1rem;
  }
</style>
