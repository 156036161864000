import { endpoint } from "./configuration";
import { createMavlink } from "./mavlink";
import { createSerialChannel, pairedSerialPort } from "./serial";
import { createVehicle } from "./vehicle";
import { createWebSocketChannel } from "./websocket";

export const connect = async () =>
  await createVehicle(createMavlink(await channel()));

const channel = async () => {
  const port = await pairedSerialPort();
  if (port) return createSerialChannel(port);
  else return createWebSocketChannel(endpoint);
};
