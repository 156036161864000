<script lang="ts">
  import logo from "../logo.svg";
  import {
    dropMission,
    exportWaypoints,
    importWaypoints,
    missionDrop,
  } from "../mission";
  import type { Drop, Editing, Position, Vehicle } from "../model";
  import { positionEmpty } from "../position";
  import { blobToString, exportFile, importFile } from "../world/browser";
  import { feetPerMeter } from "../world/constants";
  import Button from "./Button.svelte";
  import Field from "./Field.svelte";
  import DownloadIcon from "./icons/DownloadIcon.svelte";
  import UploadIcon from "./icons/UploadIcon.svelte";
  import LoadingButton from "./LoadingButton.svelte";
  import NumberInput from "./NumberInput.svelte";
  import Overlay from "./Overlay.svelte";
  import PointInput from "./PositionInput.svelte";

  export let vehicle: Vehicle | undefined;
  export let drop: Drop;
  export let onChangeDrop: (_: Partial<Drop>) => void;
  export let onEditing: (_: Editing | undefined) => void;

  $: ({ start, end, drop: position, glideSlope, dropAltitude } = drop);

  const onChangeDropAltitude = (_: number) => {
    const dropAltitude = _ / feetPerMeter;
    onChangeDrop({ dropAltitude });
  };

  const onChangeGlideSlope = (glideSlope: number) =>
    onChangeDrop({ glideSlope });

  const onChangeStart = (start: Position) => {
    onChangeDrop({ start });
    if (positionEmpty(start)) onEditing("start");
  };

  const onChangeEnd = (end: Position) => {
    onChangeDrop({ end });
    if (positionEmpty(end)) onEditing("end");
  };

  const onChangePosition = (drop: Position) => {
    onChangeDrop({ drop });
    if (positionEmpty(drop)) onEditing("drop");
  };

  const onExport = () => {
    const waypoints = exportWaypoints(dropMission(drop));
    exportFile(new Blob([waypoints]), "drop.waypoints");
  };

  const onImport = async () =>
    onChangeDrop({
      ...missionDrop(
        await importWaypoints(
          await blobToString(await importFile(".waypoints")),
        ),
      ),
    });

  const onFocusStart = () => {
    if (positionEmpty(drop.start)) onEditing("start");
  };
  const onFocusEnd = () => {
    if (positionEmpty(drop.end)) onEditing("end");
  };
  const onFocusDrop = () => {
    if (positionEmpty(drop.drop)) onEditing("drop");
  };
  const onDoneEditing = () => onEditing(undefined);

  const onExecute = async () => {
    onDoneEditing();
    await vehicle?.drop(drop);
  };
</script>

<div class="sidebar">
  <Overlay>
    <div class="content">
      <img class="logo" src={logo} alt="Logo" />
      <h1>Mission Planner</h1>
      <Field label="Drop altitude (ft MSL)">
        <NumberInput
          value={Math.round(dropAltitude * feetPerMeter)}
          onChange={onChangeDropAltitude}
        />
      </Field>
      <Field label="Glide slope">
        <NumberInput value={glideSlope} onChange={onChangeGlideSlope} />
      </Field>
      <Field label="Drop zone start">
        <PointInput
          value={start}
          onChange={onChangeStart}
          on:focus={onFocusStart}
          on:blur={onDoneEditing}
        />
      </Field>
      <Field label="Drop zone end">
        <PointInput
          value={end}
          onChange={onChangeEnd}
          on:focus={onFocusEnd}
          on:blur={onDoneEditing}
        />
      </Field>
      <Field label="Drop position">
        <PointInput
          value={position}
          onChange={onChangePosition}
          on:focus={onFocusDrop}
          on:blur={onDoneEditing}
        />
      </Field>
      <div class="buttons">
        {#if vehicle}
          <LoadingButton
            disabled={positionEmpty(start) ||
              positionEmpty(end) ||
              positionEmpty(drop.drop)}
            onClick={onExecute}
          >
            Execute...
          </LoadingButton>
        {/if}
        <Button icon on:click={onExport}>
          <DownloadIcon />
        </Button>
        <Button icon on:click={onImport}>
          <UploadIcon />
        </Button>
      </div>
    </div>
  </Overlay>
</div>

<style>
  .sidebar {
    position: absolute;
    top: 1rem;
    right: 1rem;

    & > * > .content {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      & .logo {
        height: 2rem;
      }

      & h1 {
        margin: 0;
        font-size: 1.75rem;
      }

      & .buttons {
        display: flex;
        gap: 1rem;
      }
    }
  }
</style>
