<script lang="ts">
</script>

<div class="overlay"><slot /></div>

<style>
  .overlay {
    padding: 1rem;
    backdrop-filter: grayscale(75%) contrast(75%) brightness(50%) blur(0.25rem);
    -webkit-backdrop-filter: grayscale(75%) contrast(75%) brightness(50%)
      blur(0.25rem);
    border-radius: 0.25rem;
  }
</style>
