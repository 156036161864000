<script lang="ts">
  import Button from "./Button.svelte";
  import Loading from "./Loading.svelte";

  export let disabled = false;
  export let onClick: () => Promise<void>;
  let loading = false;

  const _onClick = async () => {
    loading = true;
    try {
      await onClick();
    } finally {
      loading = false;
    }
  };
</script>

<Button {disabled} on:click={_onClick}>
  <div class="content" class:loading>
    <slot /><Loading visible={loading} />
  </div>
</Button>

<style>
  .content {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    &.loading {
      pointer-events: none;
    }

    & svg {
      margin: -0.5rem 0;
    }
  }
</style>
