<script lang="ts">
  export let icon = false;
  export let disabled = false;
</script>

<button {disabled} on:click on:mousedown on:mouseup {...$$restProps} class:icon>
  <slot />
</button>

<style>
  button {
    appearance: none;
    padding: 0.5rem 1rem;
    background: #ffffff22;
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    border: 0.125rem solid transparent;
    border-radius: 0.25rem;
    transition: border-color 200ms;

    &.icon {
      padding: 0.5rem;
      font-size: 0;

      & svg {
        width: 1.25rem;
        height: 1.25rem;
      }
    }

    &:focus,
    &:hover:not([disabled]) {
      border-color: white;
      cursor: pointer;
    }

    &[disabled] {
      color: #ffffff22;
    }
  }
</style>
