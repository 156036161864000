<script lang="ts">
  import * as mgrs from "mgrs";
  import type { ChangeEventHandler } from "svelte/elements";

  import { debounce } from "../common";
  import { ground } from "../context";
  import { geocode } from "../geocode";
  import type { Position } from "../model";
  import { boundsCenter } from "../model";
  import { positionEmpty } from "../position";

  export let value: Position;
  export let onChange: (_: Position) => void;

  let valueString: string | undefined;

  const onEdited = debounce(() => {
    valueString = undefined;
  }, 1000);

  const positionString = (_: Position) => {
    if (positionEmpty(_)) return "";
    const [longitude, latitude] = _;
    return mgrs.forward([longitude, latitude]);
  };

  const onChangeText: ChangeEventHandler<HTMLInputElement> = async ({
    currentTarget,
  }) => {
    const { value = "" } = currentTarget ?? {};
    valueString = value;
    onEdited();
    const bounds = await geocode(value);
    if (!bounds) onChange([0, 0, 0]);
    else onChange(ground(boundsCenter(bounds)));
  };
</script>

<input
  type="search"
  value={valueString ?? positionString(value)}
  on:input={onChangeText}
  on:focus
  on:blur
/>
