import type { Channel } from "./channel";
import { delay } from "./common";
import { createSubscriber } from "./subscriber";

export const createSerialChannel = (port: SerialPort) => {
  let running = true;
  const { subscribe: read, emit } = createSubscriber<Uint8Array>();
  let writer: WritableStreamDefaultWriter<Uint8Array> | undefined;

  const open = async () => {
    for (;;) {
      let reader: ReadableStreamDefaultReader<Uint8Array> | undefined;
      try {
        if (port.readable || port.writable) await port.close();
        await port.open({ baudRate: 115200 });

        reader = port.readable?.getReader();
        writer = port.writable?.getWriter();

        while (running && reader) {
          const { value, done } = await reader.read();
          if (done) break;
          emit(value);
        }
      } catch (error) {
        if (!isLost(error)) console.log("Serial error", error);
      } finally {
        writer?.releaseLock();
        reader?.releaseLock();
        writer = undefined;
        if (port.readable) await port.close();
      }
      if (!running) break;
      await delay(1000);
    }
  };

  const write = async (data: Uint8Array) => {
    try {
      await writer?.write(data);
    } catch (error) {
      if (isLost(error)) return;
      throw error;
    }
  };

  const destroy = () => {
    running = false;
  };

  void open();

  return {
    write,
    read,
    destroy,
  } satisfies Channel<Uint8Array>;
};

export const pairedSerialPort = async () => {
  if (!navigator.serial) return undefined;
  const [port] = await navigator.serial.getPorts();
  return port;
};

export const requestSerialPort = async () => {
  if (!navigator.serial) return undefined;
  const ports = await navigator.serial.getPorts();
  await Promise.all(ports.map(_ => _.forget()));
  try {
    return await navigator.serial.requestPort({
      filters: [{ usbProductId: 4184, usbVendorId: 11694 }],
    });
  } catch (error) {
    if (
      error instanceof Error &&
      (error.name === "NotFoundError" || error.name === "SecurityError")
    )
      return undefined;
    throw error;
  }
};

const isLost = (error: unknown) =>
  error instanceof Error &&
  error.name === "NetworkError" &&
  error.message === "The device has been lost.";
