import {
  cache,
  type Context,
  createContainer,
  createDynamicContainer,
  createLineLayer,
  type Properties,
} from "world.ts";

import { range } from "../common";
import { elevation } from "../context";
import { dropPath } from "../drop";
import type { Editing, Point, Position } from "../model";
import { type Drop } from "../model";
import { positionEmpty } from "../position";
import { green, red, scaleOpacity, white } from "./color";
import { createMarkerLayer } from "./marker";

export type DropLayerProperties = {
  drop?: Drop;
  editing?: Editing;
  onChangeDrop?: (_: Partial<Drop>) => void;
};

export const createDropLayer = (
  context: Context,
  properties: Properties<DropLayerProperties>,
) => {
  const { drop, editing, onChangeDrop } = properties;
  const position = cache(
    () => drop?.(),
    drop => {
      const { drop: [longitude = 0, latitude = 0] = [], dropAltitude = 0 } =
        drop ?? {};
      return [longitude, latitude, dropAltitude] satisfies Position;
    },
  );
  const start = () => drop?.()?.start ?? [0, 0, 0];
  const end = () => drop?.()?.end ?? [0, 0, 0];
  const path = cache(
    () => drop?.(),
    drop => (drop ? dropPath(drop) : []),
  );
  return createContainer([
    createMarkerLayer(context, {
      position,
      color: () => scaleOpacity(green, !positionEmpty(position()) ? 1 : 0),
      pickable: () => onChangeDrop && editing?.() !== "drop",
      onDragFlat: onChangeDrop
        ? ({ position: [longitude = 0, latitude = 0] }) => {
            const altitude = elevation([longitude, latitude]);
            const drop: Position = [longitude, latitude, altitude];
            onChangeDrop({ drop });
          }
        : undefined,
    }),
    createMarkerLayer(context, {
      position: start,
      color: () =>
        scaleOpacity(
          red,
          !positionEmpty(start()) && (positionEmpty(end()) || onChangeDrop)
            ? 1
            : 0,
        ),
      pickable: () => onChangeDrop && editing?.() !== "start",
      onDragFlat: onChangeDrop
        ? ({ position: [longitude = 0, latitude = 0] }) => {
            const altitude = elevation([longitude, latitude]);
            const start: Position = [longitude, latitude, altitude];
            onChangeDrop({ start });
          }
        : undefined,
    }),
    createMarkerLayer(context, {
      position: end,
      color: () =>
        scaleOpacity(red, !positionEmpty(end()) && onChangeDrop ? 1 : 0),
      pickable: () => onChangeDrop && editing?.() !== "end",
      onDragFlat: onChangeDrop
        ? ({ position: [longitude = 0, latitude = 0] }) => {
            const altitude = elevation([longitude, latitude]);
            const end: Position = [longitude, latitude, altitude];
            onChangeDrop({ end });
          }
        : undefined,
    }),
    createDynamicContainer({
      keys: () => range(0, (path().length - 1) / 2),
      create: i =>
        createMarkerLayer(context, {
          position: () => path()[2 * (i + 1)] ?? [0, 0, 0],
          color: () => scaleOpacity(white, onChangeDrop ? 1 : 0),
          pickable: () => !!onChangeDrop && editing?.() !== "path",
          onDragFlat: onChangeDrop
            ? ({ position: [longitude = 0, latitude = 0] }) => {
                const point: Point = [longitude, latitude];
                let { path } = drop?.() ?? {};
                if (!path) return;
                path = path.slice();
                path.splice(i, 1, point);
                onChangeDrop({ path });

                const _drop = drop?.();
                if (!_drop) return;
              }
            : undefined,
          onRightClick: onChangeDrop
            ? () => {
                let { path } = drop?.() ?? {};
                if (!path) return;
                path = path.slice();
                path.splice(i, 1);
                onChangeDrop({ path });
              }
            : undefined,
        }),
    }),
    createLineLayer(context, {
      points: cache(
        () => drop?.(),
        drop => (drop ? [[drop.start, drop.end]] : []),
      ),
      color: () =>
        scaleOpacity(
          red,
          !positionEmpty(start()) && !positionEmpty(end()) ? 1 : 0,
        ),
      width: () => 10,
      minWidthPixels: () => 24,
      polygonOffset: () => -1000,
      pickable: () => false,
    }),
    createLineLayer(context, {
      points: cache(path, _ => [_]),
      color: () => white,
      minWidthPixels: () => 5,
      maxWidthPixels: () => 5,
      polygonOffset: () => -1000,
      pickable: () => false,
    }),
  ]);
};
