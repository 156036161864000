import type { Context, Pick, Properties } from "world.ts";
import { cache, createContainer, createLineLayer } from "world.ts";

import { elevation } from "../context";
import type { Bounds } from "../model";
import { createMarkerLayer } from "./marker";

export type HighlightLayerProperties = {
  highlight: Bounds | undefined;
  onMouseMove: (_: Pick) => void;
  onClick: (_: Pick) => void;
};

export const createHighlightLayer = (
  context: Context,
  properties: Properties<HighlightLayerProperties>,
) => {
  const { highlight, onMouseMove, onClick } = properties;
  return createContainer([
    createMarkerLayer(context, {
      position: cache(highlight, highlight => {
        const [longitude = 0, latitude = 0] = highlight ?? [];
        const altitude = elevation([longitude, latitude]);
        return [longitude, latitude, altitude];
      }),
      color: cache(highlight, _ => {
        if (!_) return [0, 0, 0, 0];
        const [
          minLongitude,
          minLatitude,
          maxLongitude = minLongitude,
          maxLatitude = minLatitude,
        ] = _;
        const visible =
          minLongitude === maxLongitude && minLatitude === maxLatitude;
        return [1, 1, 0, 0.5 * (visible ? 1 : 0)];
      }),
      depth: () => false,
      pickable: () => true,
      onMouseMove,
      onClick,
    }),
    createLineLayer(context, {
      points: cache(highlight, _ => {
        if (!_) return [];
        const [
          minLongitude,
          minLatitude,
          maxLongitude = minLongitude,
          maxLatitude = minLatitude,
        ] = _;
        const ground = elevation([
          0.5 * (minLongitude + maxLongitude),
          0.5 * (minLatitude + maxLatitude),
        ]);
        return [
          [
            [minLongitude, minLatitude, ground],
            [minLongitude, maxLatitude, ground],
            [maxLongitude, maxLatitude, ground],
            [maxLongitude, minLatitude, ground],
            [minLongitude, minLatitude, ground],
          ],
        ];
      }),
      color: () => [1, 1, 0, 0.5],
      minWidthPixels: () => 5,
      maxWidthPixels: () => 5,
      depth: () => false,
      pickable: () => false,
    }),
  ]);
};
