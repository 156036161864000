import type { Context, Properties } from "world.ts";
import { createContainer, createPolygonLayer } from "world.ts";

import { range, throttle } from "../common";
import { elevation } from "../context";
import { altitudeDrop, dropPath } from "../drop";
import type { Drop, Position } from "../model";
import { positionEmpty } from "../position";
import { climb, groundBearing, move } from "./math";

const count = 64;
const rangeShape = (drop: Drop) => {
  const { glideSlope } = drop;
  const [b = [0, 0, 0], a = [0, 0, 0]] = dropPath(drop).slice(-2);
  const direction = groundBearing(b, a);
  return range(0, count)
    .map(i => (i / (count - 1)) * 2 * Math.PI)
    .map(bearing => terrainIntersection(a, glideSlope, bearing, direction));
};

const terrainIntersection = (
  position: Position,
  glideSlope: number,
  bearing: number,
  direction: number,
) => {
  let low = 0;
  const [, , altitude] = position;
  let high = 2 * (altitude - elevation(position)) * glideSlope;
  for (let i = 0; ; i++) {
    const distance = 0.5 * (low + high);
    const x = move(position, distance, bearing);
    const y = climb(x, -altitudeDrop(position, x, direction, glideSlope));
    const [, , altitude] = y;
    const ground = elevation(y);
    if (Math.abs(ground - altitude) < 10) return y;
    if (altitude < ground) high = distance;
    else low = distance;
    if (i > 10) return y;
  }
};

const invertShape = (shape: Position[]) =>
  [
    [
      [-180, 85, 0],
      [179.9999, 85, 0],
      [179.9999, -85, 0],
      [-180, -85, 0],
      [-180, 85, 0],
    ],
    shape.reverse(),
  ] satisfies Position[][];

const calculateLimit = (drop: Drop) => invertShape(rangeShape(drop));

const calculateWarning = (drop: Drop) => [
  rangeShape(drop),
  rangeShape({ ...drop, glideSlope: 0.8 * drop.glideSlope }).reverse(),
];

export type RangeLayerProperties = {
  drop?: Drop;
};

export const createRangeLayer = (
  context: Context,
  { drop }: Properties<RangeLayerProperties>,
) => {
  let cached: Drop | undefined;
  let _limit: Position[][] = [];
  let _warning: Position[][] = [];

  const update = throttle(() => {
    const _drop = drop?.();
    if (_drop === cached) return;
    cached = _drop;
    _limit = _drop ? calculateLimit(_drop) : [];
    _warning = _drop ? calculateWarning(_drop) : [];
  }, 250);

  const limit = () => {
    update();
    return _limit;
  };

  const warning = () => {
    update();
    return _warning;
  };

  const visible = () => (!positionEmpty(drop?.()?.drop ?? [0, 0]) ? 1 : 0);
  return createContainer([
    createPolygonLayer(context, {
      points: limit,
      color: () => [1, 0, 0, 0.1 * visible()],
      polygonOffset: () => -10000,
      pickable: () => false,
    }),
    createPolygonLayer(context, {
      points: warning,
      color: () => [1, 1, 0, 0.1 * visible()],
      polygonOffset: () => -10000,
      pickable: () => false,
    }),
  ]);
};
