import type { Point } from "./model";
import type { Position } from "./model";
import type { World } from "./world";

export let world: World | undefined;

export const setWorld = (_: World) => {
  world = _;
};

export const elevation = (_: Position | Point) => world?.elevation(_) ?? 0;

export const ground = ([longitude, latitude]: Position | Point) => {
  const ground = elevation([longitude, latitude]);
  return [longitude, latitude, ground] satisfies Position;
};

export const project = (_: Position) => world?.project(_) ?? [0, 0];
