<script lang="ts"></script>

<svg viewBox="0 0 32 32">
  <path
    d="M26,24v4H6V24H4v4H4a2,2,0,0,0,2,2H26a2,2,0,0,0,2-2h0V24Z"
    fill="currentColor"
  />
  <polygon
    points="26 14 24.59 12.59 17 20.17 17 2 15 2 15 20.17 7.41 12.59 6 14 16 24 26 14"
    fill="currentColor"
  />
</svg>
