<script lang="ts">
  import type { ChangeEventHandler } from "svelte/elements";

  import { geocode } from "../geocode";
  import type { Bounds } from "../model";
  import Field from "./Field.svelte";
  import Overlay from "./Overlay.svelte";

  let value = "";
  export let onGeocode: (_: Bounds | undefined) => void;

  const onChange: ChangeEventHandler<HTMLInputElement> = ({
    currentTarget,
  }) => {
    ({ value } = currentTarget);
  };

  const onSubmit = async (event: Event) => {
    event.preventDefault();
    onGeocode(await geocode(value));
    value = "";
  };
</script>

<div class="search">
  <Overlay>
    <form on:submit={onSubmit}>
      <Field label="">
        <svg fill="currentColor" viewBox="0 0 16 16">
          <path
            d="M15,14.3L10.7,10c1.9-2.3,1.6-5.8-0.7-7.7S4.2,0.7,2.3,3S0.7,8.8,3,10.7c2,1.7,5,1.7,7,0l4.3,4.3L15,14.3z M2,6.5	C2,4,4,2,6.5,2S11,4,11,6.5S9,11,6.5,11S2,9,2,6.5z"
          >
          </path>
        </svg>
        <input
          type="search"
          {value}
          on:change={onChange}
          placeholder="Location or MGRS"
        />
      </Field>
    </form>
  </Overlay>
</div>

<style>
  .search {
    position: absolute;
    bottom: 1rem;
    left: 1rem;

    & svg {
      width: 1.25rem;
      height: 1.25rem;
      margin-bottom: -2rem;
    }

    & input {
      padding-top: 0.25rem;
      padding-left: 1.75rem;
    }
  }
</style>
