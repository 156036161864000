import * as mgrs from "mgrs";

import { mapboxToken } from "./configuration";
import type { Bounds } from "./model";

export const geocode = async (value: string) =>
  parseMgrs(value) ?? parseCoordinate(value) ?? (await search(value));

const search = async (value: string) => {
  const response = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?access_token=${mapboxToken}&types=country,region,district,place,postcode,address`,
  );
  const results = (await response.json()) as GeoJSON.FeatureCollection;
  const [feature] = results.features;
  if (!feature) return;
  const { bbox } = feature;
  if (bbox) {
    const [minLongitude, minLatitude, maxLongitude, maxLatitude] = bbox;
    return [
      minLongitude,
      minLatitude,
      maxLongitude,
      maxLatitude,
    ] satisfies Bounds;
  }
  return (feature as unknown as { center: [number, number] } | undefined)
    ?.center;
};

const parseMgrs = (value: string) => {
  try {
    return mgrs.inverse(value.replace(/\s/g, ""));
  } catch (error) {
    // Ignore
  }
};

const parseCoordinate = (value: string) => {
  const match = /^(-?\d*(\.\d+)?),?\s*(-?\d*(\.\d+)?)$/.exec(value);
  if (match) {
    const longitude = parseFloat(match[3] ?? "");
    const latitude = parseFloat(match[1] ?? "");
    if (isNaN(longitude) || isNaN(latitude)) return undefined;
    return [longitude, latitude] satisfies Bounds;
  }
};
