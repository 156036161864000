import type { vec3 } from "gl-matrix";
import type { Context, Properties } from "world.ts";
import { createPositionTransition } from "world.ts";

import type { Position } from "../model";
import { positionEmpty } from "../position";
import { blue, scaleOpacity } from "./color";
import { createMarkerLayer } from "./marker";

export type TargetLayerProperties = {
  position: vec3;
};

export const createTargetLayer = (
  context: Context,
  properties: Properties<TargetLayerProperties>,
) => {
  const position = createPositionTransition(properties.position);
  const color = () =>
    scaleOpacity(blue, positionEmpty(position() as Position) ? 0 : 1);
  return createMarkerLayer(context, { position, color });
};
