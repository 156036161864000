<script lang="ts"></script>

<svg viewBox="0 0 32 32">
  <polygon
    points="6 18 7.41 19.41 15 11.83 15 30 17 30 17 11.83 24.59 19.41 26 18 16 8 6 18"
    fill="currentColor"
  />
  <path
    d="M6,8V4H26V8h2V4a2,2,0,0,0-2-2H6A2,2,0,0,0,4,4V8Z"
    fill="currentColor"
  />
</svg>
