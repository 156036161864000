import type { Point, Position } from "../model";

const earthRadius = 6371000;

export const toRadians = (x: number) => (x / 180) * Math.PI;
export const toDegrees = (x: number) => (x * 180) / Math.PI;

export const move = (position: Position, meters: number, bearing: number) => {
  const [, , altitude] = position;
  const [longitude = 0, latitude = 0] = position.map(toRadians);
  const q = meters / earthRadius;
  const latitude2 = Math.asin(
    Math.sin(latitude) * Math.cos(q) +
      Math.cos(latitude) * Math.sin(q) * Math.cos(bearing),
  );
  const longitude2 =
    longitude +
    Math.atan2(
      Math.sin(bearing) * Math.sin(q) * Math.cos(latitude),
      Math.cos(q) - Math.sin(latitude) * Math.sin(latitude2),
    );
  return [
    toDegrees(longitude2),
    toDegrees(latitude2),
    altitude,
  ] satisfies Position;
};

export const climb = (position: Position, offset: number) => {
  const [longitude, latitude, altitude] = position;
  return [longitude, latitude, altitude + offset] satisfies Position;
};

export const groundDistance = (a: Position | Point, b: Position | Point) => {
  const [longitudeA = 0, latitudeA = 0] = a.map(toRadians);
  const [longitudeB = 0, latitudeB = 0] = b.map(toRadians);
  const dLatitude = latitudeB - latitudeA;
  const dLongitude = longitudeB - longitudeA;
  const c =
    Math.sin(dLatitude / 2) * Math.sin(dLatitude / 2) +
    Math.sin(dLongitude / 2) *
      Math.sin(dLongitude / 2) *
      Math.cos(latitudeA) *
      Math.cos(latitudeB);
  return earthRadius * 2 * Math.atan2(Math.sqrt(c), Math.sqrt(1 - c));
};

export const groundBearing = (a: Position | Point, b: Position | Point) => {
  const [longitudeA = 0, latitudeA = 0] = a.map(toRadians);
  const [longitudeB = 0, latitudeB = 0] = b.map(toRadians);
  const dLongitude = longitudeB - longitudeA;
  const y = Math.sin(dLongitude) * Math.cos(latitudeB);
  const x =
    Math.cos(latitudeA) * Math.sin(latitudeB) -
    Math.sin(latitudeA) * Math.cos(latitudeB) * Math.cos(dLongitude);
  return Math.atan2(y, x);
};
