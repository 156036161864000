import type { vec3, vec4 } from "gl-matrix";
import type { Context, Pick, Properties } from "world.ts";
import { createBillboardLayer, createContainer } from "world.ts";

import { createStemLayer } from "./stem";

export type MarkerLayerProperties = {
  position: vec3;
  color: vec4;
  depth?: boolean;
  pickable?: boolean;
  onMouseMove?: (_: Pick) => void;
  onClick?: (_: Pick) => void;
  onRightClick?: (_: Pick) => void;
  onDragFlat?: (_: Pick) => void;
};

export const createMarkerLayer = (
  context: Context,
  properties: Properties<MarkerLayerProperties>,
) => {
  const {
    position,
    color,
    depth = () => true,
    pickable = () => false,
    onClick,
    onRightClick,
    onDragFlat,
  } = properties;
  return createContainer([
    createBillboardLayer(context, {
      url: () => new URL("marker.png", import.meta.url).toString(),
      position,
      size: () => 1000,
      minSizePixels: () => 16,
      maxSizePixels: () => 24,
      color,
      polygonOffset: () => -10000,
      depth,
      pickable,
      onClick,
      onRightClick,
      onDragFlat,
    }),
    createStemLayer(context, {
      position,
      color,
    }),
  ]);
};
