<script lang="ts">
  import { onMount } from "svelte";

  import { world } from "../context";
  import { toDegrees } from "../world/math";

  let yaw = 0;

  onMount(() => {
    let running = true;
    const frame = () => {
      if (!running) return;
      [, , yaw = 0] = world?.view.orientation ?? [];
      requestAnimationFrame(frame);
    };
    requestAnimationFrame(frame);
    return () => {
      running = false;
    };
  });
</script>

<svg
  width="2rem"
  height="2rem"
  viewBox="0 0 48 48"
  class="compass"
  style="transform: rotate({toDegrees(yaw)}deg)"
>
  <circle cx="24" cy="24" r="24" fill="#222c" />
  <path
    d="M23.9982 48C37.2326 48 48 37.2344 48 24C48 10.7665 37.2335 0 23.9982 0C10.7656 0 0 10.7656 0 24C0 37.2335 10.7656 48 23.9982 48ZM23.9982 3.60144C35.2473 3.60144 44.3986 12.7527 44.3986 24C44.3986 35.2482 35.2473 44.3986 23.9982 44.3986C12.7518 44.3986 3.60144 35.2482 3.60144 24C3.60144 12.7518 12.7518 3.60144 23.9982 3.60144Z"
    fill="currentColor"
  />
  <path d="M23.5 11L19 24H28L23.5 11Z" fill="red" />
  <path d="M23.5 37L19 24H28L23.5 37Z" fill="currentColor" />
</svg>

<style>
  .compass {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
  }
</style>
