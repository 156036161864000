import type { vec3 } from "gl-matrix";
import type { Context, Properties } from "world.ts";
import { createLineLayer } from "world.ts";

export type MissionLayerProperties = {
  mission: vec3[][];
};

export const createMissionLayer = (
  context: Context,
  properties: Properties<MissionLayerProperties>,
) => {
  const { mission } = properties;
  return createLineLayer(context, {
    points: mission,
    color: () => [0.9, 0.9, 0.9, 1],
    minWidthPixels: () => 5,
    maxWidthPixels: () => 5,
    polygonOffset: () => -1000,
    pickable: () => false,
  });
};
