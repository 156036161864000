import { ardupilotmega, common, minimal } from "node-mavlink";

import { delay } from "./common";
import type { Mavlink } from "./mavlink";
import { dropMission, missionDrop, missionPaths } from "./mission";
import type { Drop, Position, State, Vehicle } from "./model";
import { positionEmpty } from "./position";
import { createVehicleMissions } from "./vehicle-missions";

const {
  MavCmd,
  GlobalPositionInt,
  CommandLong,
  Attitude,
  PositionTargetGlobalInt,
  StatusText,
  MavResult,
  CommandAck,
  ParamSet,
  ParamValue,
} = common;
const { PlaneMode } = ardupilotmega;
const { Heartbeat } = minimal;

const initialState: State = {
  time: 0,
  bootTime: 0,
  rebooting: false,
  armed: false,
  position: [0, 0, 0],
  orientation: [0, 0, 0],
  target: [0, 0, 0],
  path: [],
  mission: [],
};

export const createVehicle = async (mavlink: Mavlink) => {
  const state = initialState;

  const vehicleMissions = createVehicleMissions(mavlink);

  const reset = () => {
    state.path = [];
  };

  const unsubscribe = mavlink.read(async message => {
    state.time = Date.now();
    if (message instanceof GlobalPositionInt) {
      const latitude = message.lat / 1e7;
      const longitude = message.lon / 1e7;
      const altitude = message.alt / 1e3;
      state.position = [longitude, latitude, altitude];
    } else if (message instanceof Attitude) {
      const { pitch, roll, yaw } = message;
      state.orientation = [pitch, roll, yaw];
    } else if (message instanceof PositionTargetGlobalInt) {
      const latitude = message.latInt / 1e7;
      const longitude = message.lonInt / 1e7;
      const altitude = message.alt;
      state.target = [longitude, latitude, altitude];
    } else if (message instanceof Heartbeat)
      state.armed = !!(message.baseMode & minimal.MavModeFlag.SAFETY_ARMED);
    else if (message instanceof StatusText) {
      console.log(message.text);
      if (message.text === "Flight plan received") await load();
      else if (message.text === "ArduPilot Ready") reset();
    }
    if ("timeBootMs" in message && typeof message.timeBootMs === "number")
      state.bootTime = message.timeBootMs;
  });

  const heartbeat = async () => {
    const message = new minimal.Heartbeat();
    message.type = minimal.MavType.GCS;
    message.autopilot = minimal.MavAutopilot.INVALID;
    message.systemStatus = minimal.MavState.ACTIVE;
    await mavlink.write(message);
  };

  const sendCommand = async (
    message: common.CommandLong | common.CommandInt,
    cancel?: Promise<never>,
  ) => {
    const result = await mavlink.retry(
      message,
      mavlink.receive(CommandAck, _ => _.command === message.command),
      cancel,
    );
    if (result.result !== MavResult.ACCEPTED)
      console.warn("Command failure", result);
  };

  const getParameter = async (name: string, cancel?: Promise<never>) => {
    const message = new common.ParamRequestRead();
    message.targetComponent = 1;
    message.paramId = name;
    message.paramIndex = -1;

    const { paramValue: value } = await mavlink.retry(
      message,
      mavlink.receive(ParamValue, _ => _.paramId === name),
      cancel,
    );
    return value;
  };

  const setParameter = async (
    name: string,
    value: number,
    cancel?: Promise<never>,
  ) => {
    const message = new ParamSet();
    message.targetComponent = 0;
    message.paramId = name;
    message.paramValue = value;

    await mavlink.retry(
      message,
      mavlink.receive(ParamValue, _ => _.paramId === name),
      cancel,
    );
  };

  const reboot = async (position: Position) => {
    await disarm();

    const [longitude, latitude, altitude] = position;

    await Promise.all([
      setParameter("SIM_OPOS_LAT", latitude),
      setParameter("SIM_OPOS_LNG", longitude),
      setParameter("SIM_OPOS_ALT", altitude),
    ]);

    const message = new CommandLong();
    message.command = MavCmd.PREFLIGHT_REBOOT_SHUTDOWN;
    message._param1 = 1;
    await sendCommand(message);

    state.rebooting = true;
    await delay(2000);
    state.rebooting = false;
  };

  const armDisarm = async (arm: boolean) => {
    const message = new CommandLong();
    message.targetComponent = 0;
    message.command = MavCmd.COMPONENT_ARM_DISARM;
    message._param1 = arm ? 1 : 0;
    message._param2 = 21196;
    await sendCommand(message);
  };

  const arm = () => armDisarm(true);

  const disarm = () => armDisarm(false);

  const auto = async () => {
    const message = new CommandLong();
    message.command = MavCmd.DO_SET_MODE;
    message._param1 = 1;
    message._param2 = PlaneMode.AUTO;
    await sendCommand(message);
  };

  const writeDrop = async (drop: Drop, cancel?: Promise<never>) =>
    await vehicleMissions.write(dropMission(drop), cancel);

  const drop = async (drop: Drop) => {
    state.mission = [];
    state.drop = undefined;
    const { glideSlope, dropAltitude } = drop;
    const [, , ground] = drop.drop;

    await setParameter("SCR_USER2", glideSlope);

    try {
      await setParameter(
        "SIM_GD2K_ALT",
        dropAltitude - ground,
        delay(1000).then(() => {
          throw "Timeout";
        }),
      );
      const [longitude, latitude] = drop.drop;
      await reboot([longitude, latitude, ground]);
    } catch (error) {
      if (error !== "Timeout") throw error;
    }

    await delay(2000);
    await writeDrop(drop);
    await delay(1000);
    await arm();
    await delay(1000);
    await auto();
    await load();
  };

  const load = async () => {
    const glideSlope = await getParameter("SCR_USER2");
    const mission = await vehicleMissions.read();
    const drop = missionDrop(mission);
    state.drop = drop ? { ...drop, glideSlope } : undefined;
    state.mission = missionPaths(mission);
  };

  const step = async () => {
    await heartbeat();
    if (state.rebooting || positionEmpty(state.position)) return;
    state.path.unshift(state.position);
    if (state.path.length > 1000) state.path.length = 1000;
  };

  const interval = setInterval(step, 200);

  const destroy = () => {
    clearInterval(interval);
    unsubscribe();
    mavlink.destroy();
  };

  await mavlink.receive(minimal.Heartbeat);

  return {
    get state() {
      return state;
    },
    load,
    drop,
    reboot,
    destroy,
  } satisfies Vehicle;
};
