export type Point = [longitude: number, latitude: number];

export type Position = [longitude: number, latitude: number, altitude: number];

export type Orientation = [pitch: number, roll: number, yaw: number];

export type State = {
  time: number;
  bootTime: number;
  rebooting: boolean;
  armed: boolean;
  position: Position;
  orientation: Orientation;
  target: Position;
  path: Position[];
  mission: Position[][];
  drop?: Drop;
};

export type Vehicle = {
  state: State;
  load: () => Promise<void>;
  drop: (drop: Drop) => Promise<void>;
  reboot: (position: Position) => Promise<void>;
  destroy: () => void;
};

export type Drop = {
  start: Position;
  end: Position;
  drop: Position;
  path: Point[];
  dropAltitude: number;
  glideSlope: number;
};

export const editing = ["start", "end", "drop"] as const;
export type Editing = (typeof editing)[number];

export type Bounds = [
  minLongitude: number,
  minLatitude: number,
  maxLongitude?: number,
  maxLatitude?: number,
];

export const boundsCenter = ([
  minLongitude,
  minLatitude,
  maxLongitude = minLongitude,
  maxLatitude = minLatitude,
]: Bounds) =>
  [
    0.5 * (maxLongitude + minLongitude),
    0.5 * (maxLatitude + minLatitude),
  ] satisfies Point;
