export const importFile = (accept: string) => {
  let resolve: (file: Blob) => void = () => [];

  const input = document.createElement("input");
  input.accept = accept;
  input.type = "file";

  input.addEventListener("change", () => {
    const file = input.files?.[0];
    if (file) resolve(file);
  });
  input.click();

  return new Promise<Blob>(_ => (resolve = _));
};

export const exportFile = (contents: Blob, name: string) => {
  const a = document.createElement("a");
  a.style.display = "none";
  a.setAttribute("href", URL.createObjectURL(contents));
  a.setAttribute("download", name);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export const blobToString = (file: Blob) =>
  new Promise<string>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = ({ target }) => {
      if (!target) return;
      if (target.error) reject(target.error);
      else resolve(target.result as string);
    };
    reader.readAsText(file);
  });
