import { common } from "node-mavlink";

import { defaultGlideSlope } from "./configuration";
import type { Drop, Position } from "./model";

const { MavCmd, MavFrame, MissionItemInt } = common;

export const missionPaths = (items: common.MissionItemInt[]) => {
  const groups: Position[][] = [];
  let current: Position[] = [];

  items.forEach((item, i) => {
    if (i === 0) return;

    const position = itemPosition(item);
    if (position) current.push(position);

    if (item.command === MavCmd.DO_JUMP) {
      const index = item.param1;
      const target = items[index];
      if (target) {
        const position = items
          .slice(index)
          .map(_ => itemPosition(_))
          .find((_): _ is Position => !!_);
        if (position) current.push(position);
      }
    }

    const terminal = [MavCmd.NAV_LAND, MavCmd.DO_JUMP].includes(item.command);

    if (terminal && current.length > 0) {
      groups.push(current);
      current = [];
    }
  });

  if (current.length > 0) groups.push(current);

  return groups;
};

export const itemPosition = (item: common.MissionItemInt) => {
  const latitude = item.x / 1e7;
  const longitude = item.y / 1e7;
  const altitude = item.z;

  if (latitude === 0 && longitude === 0) return undefined;

  return [longitude, latitude, altitude] satisfies Position | undefined;
};

export const missionDrop = (mission: common.MissionItemInt[]) => {
  const [home] = mission;
  if (!home) return;
  const position = mission.find(_ => _.command === MavCmd.DO_LAND_START);
  if (!position) return;
  const [, , ground = 0] = itemPosition(position) ?? [];
  const [longitude = 0, latitude = 0, dropAltitude = 0] =
    itemPosition(position) ?? [];
  const lands = mission.filter(_ => _.command === MavCmd.NAV_LAND);
  if (lands.length !== 2) return;
  const [start, end] = lands.map(itemPosition);
  if (!start || !end) return;
  const drop: Position = [longitude, latitude, ground];
  return {
    start,
    end,
    drop,
    path: [],
    glideSlope: defaultGlideSlope,
    dropAltitude,
  } satisfies Drop;
};

const missionItem = ([longitude, latitude, altitude]: Position) => {
  const item = new MissionItemInt();
  item.frame = MavFrame.GLOBAL_INT;
  item.command = MavCmd.NAV_WAYPOINT;
  item.x = latitude * 1e7;
  item.y = longitude * 1e7;
  item.z = altitude;
  return item;
};

export const dropMission = (drop: Drop) => {
  const { start, end, dropAltitude } = drop;
  const [longitude = 0, latitude = 0] = drop.drop;

  const position: Position = [longitude, latitude, dropAltitude];

  const home = missionItem(position);

  const startWaypoint = missionItem(start);
  startWaypoint.command = MavCmd.NAV_LAND;

  const endWaypoint = missionItem(end);
  endWaypoint.command = MavCmd.NAV_LAND;

  const dropWaypoint = missionItem(position);
  dropWaypoint.command = MavCmd.DO_LAND_START;

  return [home, startWaypoint, endWaypoint, dropWaypoint];
};

const waypointsFilePrefix = "QGC WPL 110";

export const importWaypoints = async (data: string) => {
  const [first, ...lines] = data
    .split("\n")
    .map(_ => _.trim())
    .filter(_ => !!_);
  if (first !== waypointsFilePrefix) throw new Error("Invalid waypoints file");
  return await Promise.all(
    lines.map(line => {
      const [
        seq = 0,
        ,
        frame = 0,
        command = 0,
        param1 = 0,
        param2 = 0,
        param3 = 0,
        param4 = 0,
        param5 = 0,
        param6 = 0,
        param7 = 0,
      ] = line.split(/\s+/).map(parseFloat);

      const item = new common.MissionItemInt();
      item.seq = seq;
      item.frame = frame;
      item.command = command;
      item.param1 = param1;
      item.param2 = param2;
      item.param3 = param3;
      item.param4 = param4;
      item.x = param5 * 1e7;
      item.y = param6 * 1e7;
      item.z = param7;

      return item;
    }),
  );
};

export const exportWaypoints = (waypoints: common.MissionItemInt[]) =>
  [
    waypointsFilePrefix,
    ...waypoints.map(
      ({ frame, command, param1, param2, param3, param4, x, y, z }, seq) =>
        [
          seq,
          0,
          frame,
          command,
          param1,
          param2,
          param3,
          param4,
          x / 1e7,
          y / 1e7,
          z,
          1,
        ].join("\t"),
    ),
  ].join("\n");
