import { vec3 } from "gl-matrix";
import type { Properties, View } from "world.ts";
import { circumference, createPositionTransition, mercator } from "world.ts";

import type { Bounds } from "../model";

export type FollowViewProperties = {
  enabled: boolean;
  position: vec3 | undefined;
  distance: number;
};

export const createFollowView = (
  properties: Properties<FollowViewProperties>,
) => {
  const { enabled, distance } = properties;

  const position = createPositionTransition(
    () => properties.position() ?? [0, 0, 0],
  );

  return () => {
    if (!enabled()) return {};
    return {
      target: position(),
      offset: [-150, 0],
      ...(distance() > 10000 ? { distance: 10000 } : {}),
    } satisfies Partial<View>;
  };
};

export type BoundsViewProperties = {
  bounds: Bounds | undefined;
};

export const createBoundsView =
  (properties: Properties<BoundsViewProperties>) => () => {
    const bounds = properties.bounds();
    if (!bounds) return {};
    const [
      minLongitude,
      minLatitude,
      maxLongitude = minLongitude,
      maxLatitude = minLatitude,
    ] = bounds;
    const target = [
      (minLongitude + maxLongitude) / 2,
      (minLatitude + maxLatitude) / 2,
      0,
    ] satisfies vec3;
    const distance = Math.max(
      10000,
      vec3.distance(
        mercator([minLongitude, minLatitude, 0]),
        mercator([maxLongitude, maxLatitude, 0]),
      ) * circumference,
    );
    return {
      target,
      distance,
      offset: [0, 0],
    } satisfies Partial<View>;
  };
