import { common } from "node-mavlink";
import limit from "p-limit";

import { range } from "./common";
import type { Mavlink } from "./mavlink";

const {
  MavMissionType,
  MavMissionResult,
  MissionItemInt,
  MissionRequestInt,
  MissionRequestList,
  MissionRequest,
  MissionAck,
  MissionCount,
} = common;

export const createVehicleMissions = (mavlink: Mavlink) => {
  const read = async (cancel?: Promise<never>) => {
    for (;;)
      try {
        const requestList = new MissionRequestList();
        requestList.targetComponent = 0;
        requestList.missionType = MavMissionType.MISSION;

        const { count } = await mavlink.retry(
          requestList,
          mavlink.receive(MissionCount),
        );

        console.log(`Mission waypoints: ${count}`);

        const ack = mavlink
          .receive(MissionAck)
          .then(() => Promise.reject("canceled"));

        const throttle = limit(8);

        const items = await Promise.all(
          range(0, count).map(seq =>
            throttle(async () => {
              const request = new MissionRequestInt();
              request.targetComponent = 0;
              request.seq = seq;
              request.missionType = MavMissionType.MISSION;

              const item = await mavlink.retry(
                request,
                mavlink.receive(MissionItemInt, _ => _.seq === seq),
                cancel,
              );
              console.log(`Loaded mission item ${seq}`);
              return item;
            }),
          ),
        );

        const response = new MissionAck();
        response.targetComponent = 0;
        response.missionType = MavMissionType.MISSION;
        await mavlink.write(response);

        await Promise.race([ack.catch(() => []), Promise.resolve()]);

        console.log("Loading mission complete");

        return items;
      } catch (error) {
        if (error === "canceled") {
          console.log("Mission load canceled. Retrying...");
          continue;
        } else throw error;
      }
  };

  const write = async (
    items: common.MissionItemInt[],
    cancel?: Promise<never>,
  ) => {
    console.log(`Writing mission with ${items.length} waypoints`);

    const message = new MissionCount();
    message.targetComponent = 0;
    message.count = items.length;
    message.missionType = MavMissionType.MISSION;

    await mavlink.retry(
      message,
      mavlink.receive(MissionRequest, _ => _.seq === 0),
      cancel,
    );

    const complete = mavlink.receive(
      MissionAck,
      _ => _.type !== MavMissionResult.INVALID_SEQUENCE,
      cancel,
    );

    await Promise.all(
      items.map(async (item, seq) => {
        console.log(`Sending mission item ${seq}`);
        item.seq = seq;
        const last = seq === items.length - 1;
        const missionRequest = (seq: number) =>
          Promise.race([
            mavlink.receive(MissionRequest, _ => _.seq === seq),
            complete,
          ]);
        if (last) await missionRequest(seq);

        await mavlink.retry(
          item,
          Promise.race([complete, last ? complete : missionRequest(seq + 1)]),
          cancel,
        );
        console.log(`Sent mission item ${seq}`);
      }),
    );

    const ack = await complete;

    if (ack.type !== MavMissionResult.ACCEPTED) {
      console.warn(`Mission write failure: ${MavMissionResult[ack.type]}`);
      return;
    }

    console.log("Mission write complete");
  };

  return { read, write };
};
