export const delay = (timeout = 0) =>
  new Promise<void>(resolve => setTimeout(resolve, timeout));

export const signal = <T = void>() => {
  let onTrigger: ((value: T) => void) | undefined;
  const signal = new Promise<T>(resolve => {
    onTrigger = resolve;
  });
  const trigger = (value: T) => onTrigger?.(value);
  return [signal, trigger] as const;
};

export const range = (start: number, end: number) =>
  Array.from({ length: end - start }, (_, k) => k + start);

export const debounce = <F extends (...args: unknown[]) => void>(
  f: F,
  delay: number,
) => {
  let timeout: ReturnType<typeof setTimeout>;
  return (...args: Parameters<F>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => f(...args), delay);
  };
};

export const throttle = <F extends (...args: unknown[]) => unknown>(
  f: F,
  timeout: number,
) => {
  let ready = true;
  let args: Parameters<F>;
  let result: ReturnType<F>;
  return (...a: Parameters<F>) => {
    args = a;
    if (!ready) return result;

    ready = false;
    result = f(...args) as ReturnType<F>;
    setTimeout(() => {
      ready = true;
    }, timeout);
    return result;
  };
};
