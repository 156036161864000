<script lang="ts">
  import type { ChangeEventHandler } from "svelte/elements";

  export let value: number;
  export let onChange: (_: number) => void;

  const onChangeText: ChangeEventHandler<HTMLInputElement> = ({
    currentTarget,
  }) => onChange(parseFloat(currentTarget?.value ?? ""));
</script>

<input type="number" {value} on:input={onChangeText} />
