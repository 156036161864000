import type { Properties, View } from "world.ts";
import {
  cache,
  createContext,
  createMouseControl,
  createTerrainLayer,
  createViewTransition,
  createWorld as createBaseWorld,
  defaultView,
  type Pick,
} from "world.ts";

import { mapboxToken } from "./configuration";
import { ground } from "./context";
import type { Editing, Point, Position } from "./model";
import type { Bounds } from "./model";
import { type Drop, type State } from "./model";
import { positionEmpty } from "./position";
import { createDropLayer } from "./world/drop";
import { createHighlightLayer } from "./world/highlight";
import { createMissionLayer } from "./world/mission";
import { createPathLayer } from "./world/path";
import { createRangeLayer } from "./world/range";
import { createTargetLayer } from "./world/target";
import { createVehicleLayer } from "./world/vehicle";
import { createBoundsView, createFollowView } from "./world/view";

export type WorldProperties = {
  state: State | undefined;
  drop: Drop;
  editing: Editing | undefined;
  highlight: Bounds | undefined;
  onChangeDrop: (_: Partial<Drop>) => void;
  onDoneEditing: () => void;
};

export type World = {
  elevation: (_: Position | Point) => number;
  project: (_: Position) => Point;
  readonly view: View;
  dispose: () => void;
};

export const createWorld = (
  canvas: HTMLCanvasElement,
  properties: Properties<WorldProperties>,
) => {
  const { state, drop, editing, highlight, onChangeDrop, onDoneEditing } =
    properties;

  let follow: "vehicle" | "highlight" | undefined = "vehicle";

  const onFollowVehicle = () => {
    follow = "vehicle";
  };

  const context = createContext(canvas);

  let _view: View = {
    ...defaultView,
    orientation: [1, 0, 1],
    target: [0, 0, 0],
  };

  const followView = createFollowView({
    enabled: () =>
      follow === "vehicle" && !positionEmpty(state()?.position ?? [0, 0, 0]),
    position: () => state()?.position ?? [0, 0, 0],
    distance: () => _view.distance,
  });

  const highlightView = createBoundsView({
    bounds: () => (follow === "highlight" ? highlight() : undefined),
  });

  const onChangeView = (_: Partial<View>) => {
    _view = { ..._view, ..._ };
    resetView();
  };

  let viewTransition = () => defaultView;

  const resetView = () => {
    viewTransition = createViewTransition(() => {
      _view = { ..._view, ...highlightView(), ...followView() };
      return _view;
    });
  };

  resetView();

  const startFollowHighlight = cache(highlight, _ => {
    if (_) follow = "highlight";
  });

  const view = () => {
    startFollowHighlight();
    return viewTransition();
  };

  const onMouseDown = () => {
    follow = undefined;
  };

  const onChangePoint = (point: Point) => {
    if (!editing()) return;
    const position = ground(point);
    if (editing() === "start") onChangeDrop({ start: position });
    else if (editing() === "end") onChangeDrop({ end: position });
    else if (editing() === "drop") onChangeDrop({ drop: position });
  };

  const onMouseMove = ({ position: [longitude = 0, latitude = 0] }: Pick) =>
    onChangePoint([longitude, latitude]);

  const onClick = ({ position: [longitude = 0, latitude = 0] }: Pick) => {
    const point: Point = [longitude, latitude];
    onChangePoint(point);
    if (!editing()) onChangeDrop({ path: [...drop().path, point] });
    else onDoneEditing();
  };

  const world = createBaseWorld(context, {
    view,
    layers: () => layers,
  });

  const elevation = ([longitude, latitude]: Position | Point) =>
    world.elevation([longitude, latitude]);

  const project = (_: Position) => {
    const [x = 0, y = 0] = world.project(_);
    return [x, y] satisfies Point;
  };

  const control = createMouseControl(canvas, world, {
    view,
    draggable: () => follow === undefined,
    onChangeView,
  });

  const terrainLayer = createTerrainLayer(context, {
    terrainUrl: () =>
      `https://api.mapbox.com/v4/mapbox.terrain-rgb/{z}/{x}/{y}.pngraw?access_token=${mapboxToken}`,
    imageryUrl: () =>
      `https://api.mapbox.com/v4/mapbox.satellite/{z}/{x}/{y}.jpg80?access_token=${mapboxToken}`,
    onClick,
    onMouseMove,
  });

  const vehicleLayer = createVehicleLayer(context, {
    state,
    onClick: onFollowVehicle,
  });

  const targetLayer = createTargetLayer(context, {
    position: () => state()?.target ?? [0, 0, 0],
  });

  const pathLayer = createPathLayer(context, {
    path: () => state()?.path ?? [],
  });

  const missionLayer = createMissionLayer(context, {
    mission: () => state()?.mission ?? [],
  });

  const dropLayer = createDropLayer(context, {
    drop,
    editing,
    onChangeDrop,
  });

  const rangeLayer = createRangeLayer(context, {
    drop,
  });

  const highlightLayer = createHighlightLayer(context, {
    highlight,
    onClick,
    onMouseMove,
  });

  const layers = [
    terrainLayer,
    vehicleLayer,
    targetLayer,
    missionLayer,
    pathLayer,
    dropLayer,
    rangeLayer,
    highlightLayer,
  ] as const;

  canvas.addEventListener("mousedown", onMouseDown);

  const dispose = () => {
    canvas.removeEventListener("mousedown", onMouseDown);
    layers.forEach(_ => _.dispose());
    control.dispose();
    world.dispose();
  };

  return {
    elevation,
    project,
    get view() {
      return view();
    },
    dispose,
  } satisfies World;
};
